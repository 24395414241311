

// $(window).on('load', function () {
//     $('#loading').delay(1000).fadeOut(500);
//     $('header').delay(1000).queue(function() {
//         $(this).addClass('fadeInLeft');
//     });
//     $('body').delay(1000).queue(function() {
//         $(this).removeClass('is-fixed');
//     });
//
// });

(function ($) {
    // USE STRICT
    "use strict";

    try {
        $(window).scroll(function () {
            if ($(window).scrollTop() >= 600) {
                $('#header').addClass('scroll-down');
            } else {
                $('#header').removeClass('scroll-down');
            }
        });
    } catch (error) {
        console.log(error);
    }
})(jQuery);

$(".navbar-toggler").on('click', function (event) {
    // $('.navbar-toggler').addClass('collapsed').attr('aria-expanded', false);
    $('body').toggleClass('is-fixed nav-open');
    // const scrollY = document.body.style.top;
    // document.body.style.position = '';
    // document.body.style.top = '';
    // window.scrollTo(0, parseInt(scrollY || '0') * -1);

});


// .scrollTop
jQuery(window).scroll(function (event) {
    var threshold = jQuery(document).height() - jQuery(window).height() - jQuery('.copyright').height();
    if (jQuery(window).scrollTop() > 0) {
        jQuery('.scrollToTop img').css({
            opacity: 1
        });
        if (jQuery(window).scrollTop() >= threshold) {
            jQuery('.scrollToTop').css({
                top: "-5rem",
                bottom: "auto",
                position: "absolute"
            });
        } else {
            jQuery('.scrollToTop').css({
                top: "auto",
                bottom: "15px",
                position: "fixed"
            });
        }
    } else {
        jQuery('.scrollToTop img').css({
            opacity: 0
        });
    }
});

jQuery('.scrollToTop').click(function () {
    jQuery("html, body").animate({
        scrollTop: 0
    }, 500, 'swing');
});

(function () {
    function toggleClass(element, CCSclass){

        if(element.classList.contains(CCSclass)){
            element.classList.remove(CCSclass);
        } else {
            element.classList.add(CCSclass);
        }

    }

    const $ = document.querySelector.bind(document);
    const $$ = document.querySelectorAll.bind(document);
    const $$burgers = $$('.burger');
    $$burgers.forEach(($burger) => {


        if($burger.classList.contains("burger--animated")){
            $burger.addEventListener('click', function(){
                if(this.classList.contains('idle')){
                    toggleClass(this, 'idle');
                    toggleClass(this, 'activating');
                }
                if(this.classList.contains('activated')){
                    toggleClass(this, 'activated');
                    toggleClass(this, 'going-idle');
                }
            });

            $burger.addEventListener('animationend', function(){
                if(this.classList.contains('activating')){
                    toggleClass(this, 'activating');
                    toggleClass(this, 'activated');
                }
                if(this.classList.contains('going-idle')){
                    toggleClass(this, 'going-idle');
                    toggleClass(this, 'idle');
                }
            });
        } else {

            $burger.addEventListener('click', function(){
                if(this.classList.contains('idle')){
                    toggleClass($burger, 'idle');
                    toggleClass($burger, 'activated');

                } else {
                    toggleClass($burger, 'activated');
                    toggleClass($burger, 'idle');
                }
            });
        }
    });
})();