// if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
//     $('.selectpicker').selectpicker('mobile');
// } 
(function ($) {
    $('.slider-bn').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        fade: true
    }); 
    $('.slider-service').slick({

        infinite: false,
        autoplay: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        // prevArrow: '<i class="prev slick-prev"><img src="assets/images/icon/icon-arrow.svg" alt=""></i>',
        // nextArrow: '<i class="next slick-next"><img src="assets/images/icon/icon-arrow.svg" alt=""></i>',
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: false

                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
                breakpoint: 414,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            } 
        ]
    });


})(jQuery);

